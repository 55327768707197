html {
  scroll-behavior: smooth !important;
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.navigation-bar {
  height: 8rem !important;
  padding: 2.25rem 4.375rem !important;
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.mobile-navigation-bar {
  height: 8rem !important;
  padding: 1.25rem 1.375rem !important;
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
}

.mobile-navigation-bar div i{
  float: right !important;
  padding-right: 2rem !important;
}
.dark {
  background-color: var(--Main-Dark-Blue, #00121E) !important;
  /* box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.20) !important; */
  color: white !important;
  /* border: 1px solid var(--App-Inactive, #A4A4A4) !important; */
  /* border: 1px solid var(--Text-Light-Background, #F2F2F2) !important; */
}

.light {
  background-color: var(--White, #FFF) !important;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10) !important; */
  /* border: 1px solid var(--App-Inactive, #A4A4A4) !important; */
  color: black !important;
}

.navigation-logo {
  width: 13.4375rem !important;
  height: 3.125rem !important;
}

.navigation-menu {
  justify-content: center !important;
  cursor: pointer !important;
  margin: 0 auto !important;
  padding: 0 4rem !important;
  background-color: transparent !important;
  color: inherit !important;
}

.navigation-menu .item {
  color: inherit !important;
  /* Headline/Heading 4 */
  font-family: inherit !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 120% !important;
  /* 1.35rem */
}

.navigation-button {
  justify-content: center !important;
  margin: 0 auto !important;
  padding: 0 2rem !important;
  background-color: transparent !important;
}

.navigation-button.light .selection {
  background-color: transparent !important;
  border-radius: 0.25rem !important;
  color: black !important;
  border: 1px solid var(--App-Inactive, #A4A4A4) !important;
}

.navigation-button.dark .selection {
  background-color: transparent !important;
  border: 1px solid var(--Text-Light-Background, #F2F2F2) !important;
  border-radius: 0.25rem !important;
  color: white !important;
}

.navigation-button.dark .ui.selection.visible.dropdown>.text:not(.default) {
  color: white !important;
}

.navigation-button.light .ui.selection.visible.dropdown>.text:not(.default) {
  color: black !important;
}

.hero-section-customer {
  height: 56rem !important;
  margin: 0 !important;
  padding: 0 !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url(./hero_background.svg) !important;
}

.hero-section-rider {
  height: 56rem !important;
  margin: 0 !important;
  padding: 0 !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url(./hero_background_dark.svg) !important;
}

.hero-section-rider h2 {
  text-align: left !important;
  color: var(--Other-White, #FFF) !important;
  font-family: Nunito Sans !important;
  font-size: 4rem !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 100% !important;
  /* 4.8rem */
}

.hero-message-rider {
  padding: 0 0 0 4.38rem !important;
  margin-top: -13rem !important;
  text-align: center !important;
  justify-content: center !important;
  /* height: 9.6rem !important; */
}

.hero-message-rider .sub.header {
  padding: 3rem 0 1rem 0 !important;
  color: inherit !important;
}

.hero-section-rider img {
  float: right !important;
  padding-right: 4.36rem !important;
  padding-top: 12.25rem !important;
}

.hero-message {
  align-items: center !important;
  box-sizing: border-box !important;
  padding: 4rem 18.5rem 0 18.5rem !important;
  background-color: transparent !important;
  color: inherit !important;
}

.hero-message h2 {
  text-align: center !important;
  font-family: Nunito Sans !important;
  font-size: 4rem !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 120% !important;
  color: inherit !important;
  background-color: transparent !important;
  /* 4.8rem */
}

.hero-message .sub {
  padding: 2.5rem 10rem 0 10rem !important;
  text-align: center !important;
  font-family: Nunito Sans !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 160% !important;
  color: inherit !important;
  background-color: transparent !important;
  /* 1.8rem */
}

.hero-input {
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
}

.hero-section-customer .hero-input {
  display: flex !important;
}

.hero-input .fields {
  width: 30.0625rem !important;
}

.hero-section-customer .hero-input .fields {
  justify-content: center !important;
}

.hero-input .input{
  height: 3.5rem !important;
  padding: 1rem 1.25rem !important;
  border-radius: 0.25rem !important;
  border: 1px solid var(--Main-Maroon, #B2533F) !important;
  background: var(--Text-Light-Background, #F2F2F2) !important;
}

.hero-input .input input {
  width: 30.0625rem !important;
}

.hero-section-customer .hero-input {
  padding: 2.01rem 23rem 0 23rem !important;
  display: flex !important;
  float: unset !important;
}

.hero-input button {
  margin-left: 1rem !important;
  width: 11.8125rem !important;
  height: 3.5rem !important;
  flex-shrink: 0;
  border-radius: 0.25rem !important;
  background: var(--Main-Maroon, #B2533F) !important;
}

.announcement-section button {
  width: 25rem !important;
  height: 3.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  border-radius: 0.25rem !important;
  background: var(--Main-Maroon, #B2533F) !important;
  text-align: center !important;
  padding: 0.88rem 2rem 0.88rem 2rem !important;
  margin: 0rem 32.38rem 6rem 32.38rem !important;
}

.hero-input button .icon {
  padding-right: 7rem !important;
  background-color: transparent !important;
}


.hero-picture {
  padding: 2.4rem 32rem 0 32rem !important;
}

.features-section {
  padding: 6rem 3rem 9rem 3rem !important;
  margin: 0 auto !important;
  background: url(./features_background.svg), #F2F2F2 50%/ cover no-repeat !important;
}


.features-section h1 {
  color: var(--Main-Dark-Blue, #00121E) !important;
  font-family: Nunito Sans !important;
  font-size: 4rem !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 160% !important;
  /* 6.4rem */
}

.features-section .content {
  color: var(--Main-Dark-Blue, #00121E) !important;
  font-family: Nunito Sans !important;
  font-size: 3rem !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 120% !important;
  /* 3.6rem */
}

.features-section div.sub.header {
  padding-top: 1.5rem !important;
  font-family: Nunito Sans !important;
  font-size: 3rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 160% !important;
  /* 3.6rem */
  color: var(--Text-Body-text, #4F4F4F) !important;
  font-size: 1.125rem !important;
}


.features-section .ui.grid>.row {
  display: inline-block !important;
  padding: 2rem 4.5rem 2rem 4.5rem !important;
}

.features-section .linker {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1000 !important;
}

.features-section .rightImage {
  padding-right: 0 !important;
  margin-right: -3rem !important;
}


.features-section .leftImage {
  padding-left: 0 !important;
  margin-left: -3rem !important;
}

.features-section .ui.grid>.row>.column {
  padding-left: 0 !important;
  padding-right: 0 !important;

}

/* .features-section .leftImage {
  padding-left: 0 !important;
} */
.features-section .leftText {
  margin-top: -5rem !important;
  vertical-align: middle !important;
}

.features-section .rightText {
  padding-right: 0 !important;
  margin-right: -3rem !important;
  vertical-align: middle !important;
  text-align: left !important;
}

.faqs-section .sub .header {
  color: var(--Text-Body-text, #4F4F4F);
  text-align: center;
  font-family: Nunito Sans;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 1.8rem */
}

.faqs-section .hero-message {
  padding-top: 6rem !important;
}

.faqs-section .title {
  padding: 2rem 0rem !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;

  color: var(--text-heading-sub, #333) m !important;

  /* Headline/Heading 2 */
  font-family: Nunito Sans !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 120% !important;
  /* 1.8rem */
}

.faqs-section .accordion {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.announcement-section {
  background-color: var(--Background-Light-Maroon, #FAEFEC) !important;
  /* height: 31.25rem !important; */
  align-items: center !important;
  box-sizing: border-box !important;
  text-align: center !important;
  margin: 0 auto !important;
  padding-bottom: 6rem !important;
}

.announcement-section.rider {
  background-color: var(--Bckground-Light-Green, #F7FBF1) !important;
}

.announcement-section h2 {
  padding: 6rem 0 3rem 0 !important;
  color: var(--Main-Dark-Blue, #00121E) !important;
  font-family: Nunito Sans !important;
  font-size: 4rem !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 160% !important;
  /* 6.4rem */
}

.announcement-section .sub.header {
  width: 36rem !important;
  padding-top: 2rem !important;
  color: var(--Text-Body-text, #4F4F4F) !important;
  font-size: 1.125rem !important;
  font-weight: 600 !important;
}

.announcement-section button {
  width: 25rem !important;
  height: 3.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  border-radius: 0.25rem !important;
  background: var(--Main-Maroon, #B2533F) !important;
  align-items: center !important;
  text-align: center !important;
  margin: 0 auto !important;
  padding: 0.88rem 2rem 0.88rem 2rem !important;
  /* margin: 0rem 32.38rem 6rem 32.38rem !important; */
}

.announcement-section button .icon {
  padding-right: 20rem !important;
  background-color: transparent !important;
}

.footer-section {
  background: var(--Main-Dark-Blue, #00121E) !important;
  padding: 0.9375rem 4.375rem !important;
  color: var(--Other-White, #FFF) !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  /* 0.875rem */
}

.footer-section .segment {
  background-color: transparent !important;
  vertical-align: bottom !important;
  background-color: var(--Main-Dark-Blue, #00121E) !important;
}

.footer-section .soon {
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.footer-section .grid {
  margin-bottom: 0 !important;
}

.footer-logo {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  padding-top: 4rem !important;

}

.footer-logo.rider {
  padding-top: 4rem !important;
  padding-bottom: 1rem !important;
}

.footer-menu {
  cursor: pointer !important;
}

.footer-menu .item {
  color: var(--Other-White, #FFF) !important;
  /* Headline/Heading 4 */
  font-family: Nunito Sans !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 120% !important;
  /* 1.35rem */
}